import React from 'react'
import { Toaster } from 'react-hot-toast'
import { Switch } from 'react-router-dom'

import './App.css'
import { routes } from './routes'
import AppRoute from './AppRoute'
import Nav from './components/Nav'
import Cookie from './components/Cookie'
import Footer from './components/Footer'
import Loader from './components/Loader'
import { PageWrapper } from './components/shared/styled'

const App = () => (
    <>
        <Nav />
        <Toaster />
        <Loader />

        <PageWrapper>
            <Switch>
                {routes().map((route) => (
                    <AppRoute
                        exact
                        key={route.path}
                        path={route.path}
                        isPrivate={route.isPrivate}
                        component={route.component}
                    />
                ))}
            </Switch>
        </PageWrapper>

        {/* <Cookie /> */}
        <Footer />
    </>
)

export default App
