import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import Bottom from './Bottom'
import { Routes } from '@variables'
import LogoImg from '@static/images/logo.webp'
import Social from 'client/components/shared/Social'
import LanguageSwitch from 'client/components/LanguageSwitch'
import GooglePlayButton from 'client/components/shared/GooglePlayButton'
import AppleStoreButton from 'client/components/shared/AppleStoreButton'
import {
    Ul,
    Row,
    Wrap,
    Apps,
    Langs,
    Logo,
    Wrapper,
    LogoWrap,
    Contacts,
    SocialInner,
    AppsWrap,
    SocialWrap
} from './styled'

const Footer = () => {
    const [t] = useTranslation()

    return (
        <Wrap>
            <Wrapper>
                <Container style={{ maxWidth: 1338 }}>
                    <Row>
                        <LogoWrap>
                            <Link to={Routes.HOME}>
                                <Logo
                                    alt="VOS - Logo"
                                    loading="lazy"
                                    src={LogoImg}
                                    height="45"
                                    width="94"
                                />
                            </Link>
                        </LogoWrap>

                        <Ul>
                            <Typography variant="h6">
                                {t('for-individuals')}
                            </Typography>

                            <a href={Routes.FOR_INDIVIDUALS}>
                                {t('footer-menu-home')}
                            </a>
                            <a href={Routes.FOR_INDIVIDUALS_BLOG}>
                                {t('footer-menu-stories')}
                            </a>
                            <a href={Routes.FOR_INDIVIDUALS_ABOUT}>
                                {t('footer-menu-about')}
                            </a>
                        </Ul>

                        <Ul>
                            <Typography variant="h6">
                                {t('for-business')}
                            </Typography>

                            {/* <a href={Routes.FOR_BUSINESS_ABOUT}>
                                {t('footer-menu-b2b-about')}
                            </a> */}
                            <a href={Routes.FOR_BUSINESS_BLOG}>
                                {t('footer-menu-b2b-stories')}
                            </a>
                            <a href={`${Routes.FOR_BUSINESS}#b2b-form`}>
                                {t('footer-menu-b2b-demo')}
                            </a>
                        </Ul>

                        <AppsWrap>
                            <Typography variant="h6">
                                {t('footer-download')}
                            </Typography>
                            <Apps>
                                <AppleStoreButton size="medium" />
                                <GooglePlayButton size="medium" />
                            </Apps>
                        </AppsWrap>

                        <SocialWrap>
                            <Typography variant="h6">
                                {t('footer-menu-contact')}
                            </Typography>

                            <SocialInner>
                                <Social type="instagram" variant="filled" />
                                <Social type="facebook" variant="filled" />
                                <Social type="linkedin" variant="filled" />
                                <Social type="twitter" variant="filled" />
                            </SocialInner>

                            <Contacts>
                                <a href={`mailto:${t('support-email')}`}>
                                    {t('support-email')}
                                </a>
                                {/* <a href={`tel:${t('support-phone')}`}>
                                    {t('support-phone')}
                                </a> */}
                                <a
                                    target="_blank"
                                    href={Routes.CAREER}
                                    rel="noreferrer noopener"
                                >
                                    {t('career')}
                                </a>
                            </Contacts>
                        </SocialWrap>
                    </Row>

                    {/* <Langs>
                        <LanguageSwitch />
                    </Langs> */}
                </Container>
            </Wrapper>

            <Bottom />
        </Wrap>
    )
}

export default Footer
