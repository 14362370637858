import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
            {...props}
        >
            <g clipPath="url(#clip0_266_45277)">
                <path
                    fill="#000"
                    d="M5.158 10.523a.848.848 0 01.852-.849l1.411.005a.849.849 0 01.85.85v5.34c.158-.048.362-.098.586-.15a.708.708 0 00.545-.689V8.406a.85.85 0 01.849-.85h1.414a.849.849 0 01.849.85v6.148s.354-.143.699-.289a.709.709 0 00.433-.652v-7.33a.848.848 0 01.848-.85h1.415a.849.849 0 01.848.85v6.036c1.227-.89 2.47-1.959 3.456-3.245a1.425 1.425 0 00.217-1.33A9.984 9.984 0 0011.127 1C5.583.926 1.001 5.454 1.002 11a9.971 9.971 0 001.327 5.002 1.264 1.264 0 001.206.625c.267-.024.6-.057.996-.104a.707.707 0 00.627-.701v-5.3"
                ></path>
                <path
                    fill="#BFCFDA"
                    d="M5.127 19.088a9.993 9.993 0 0015.848-8.772c-3.652 5.449-10.395 7.996-15.848 8.772"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_266_45277">
                    <path
                        fill="#fff"
                        d="M0 0H20V20H0z"
                        transform="translate(1 1)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Icon
