import React, { CSSProperties } from 'react'
import styled, { keyframes } from 'styled-components'

const Pulse = keyframes`
    0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 hsla(153, 46%, 25%, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 4px hsla(153, 46%, 25%, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 hsla(153, 46%, 25%, 0);
	}
`

const Dot = styled.div`
    margin: 2px;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    transform: scale(1);
    animation: ${Pulse} 2s infinite;
    // primary.green1
    background: hsla(153, 46%, 25%, 1);
    box-shadow: 0 0 0 0 hsla(153, 46%, 25%, 1);
`

type Props = { style?: CSSProperties }

const AnimatedDot = ({ style = {} }: Props) => <Dot style={style} />

export default AnimatedDot
