import React from 'react'
import styled from 'styled-components'

import GooglePlayImg from '../../../../../static/images/googleplay.webp'

type Props = {
    readonly size: 'small' | 'medium'
    readonly style?: React.CSSProperties
}

const GooglePlayButton = ({ size, style = {} }: Props) => {
    return (
        <Wrapper
            size={size}
            style={style}
            target="_blank"
            rel="noreferrer noopener"
            href="https://play.google.com/store/apps/details?id=com.vos.app"
        >
            <img
                width="156"
                height="46"
                loading="lazy"
                src={GooglePlayImg}
                alt="download from google play"
            />
        </Wrapper>
    )
}

const Wrapper = styled.a<Props>`
    overflow: hidden;
    position: relative;
    border-radius: 7px;

    width: auto;
    height: ${({ size }) => {
        if (size === 'small') {
            return '34px'
        }
        if (size === 'medium') {
            return '40px'
        }
        return '46px'
    }};

    img {
        width: auto;
        height: ${({ size }) => {
            if (size === 'small') {
                return '34px'
            }
            if (size === 'medium') {
                return '40px'
            }
            return '46px'
        }};
    }
`

export default GooglePlayButton
