import { Route } from 'react-router-dom'
import React, { ComponentType } from 'react'

type Props = {
    path: string
    isPrivate: boolean
    component: React.FunctionComponent<TODO_ANY>
}

const AppRoute: ComponentType<TODO_ANY> = ({
    path,
    isPrivate,
    component: Component,
    ...props
}: Props) => {
    const isLoading = false

    if (isLoading) {
        return <div>loading</div>
    }

    return (
        <Route
            {...props}
            path={path}
            render={(props) => <Component {...props} />}
        />
    )
}

export default AppRoute
