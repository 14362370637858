import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
            {...props}
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M5.713 19.854h-4.05V8.246h4.05v11.608zm8.408-7.825c-.985 0-1.784.798-1.784 1.783v6.042H8.08V8.246h4.258V9.57a5.633 5.633 0 013.567-1.334c2.642 0 4.475 1.958 4.475 5.675v5.942h-4.475v-6.042a1.783 1.783 0 00-1.783-1.791v.008zm-8.333-7.8a2.083 2.083 0 11-2.084-2.083c1.144.009 2.067.939 2.067 2.083h.017z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
