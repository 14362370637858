import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'

import { Routes } from '@variables'
import {
    Links,
    Wrapper,
    Content,
    Copyright,
    BottomLink,
    GrowInMind
} from './styled'

const Bottom = () => {
    const [t] = useTranslation()

    return (
        <Wrapper>
            <Container style={{ maxWidth: 1270 }}>
                <Content>
                    <Copyright>
                        {new Date().getFullYear()} <b>VOS.health</b>
                    </Copyright>

                    <GrowInMind>
                        <span>{t('grow-in-mind')}</span>
                    </GrowInMind>

                    <Links>
                        <BottomLink>
                            <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href={Routes.PRIVACY_POLICY}
                            >
                                {t('bottom-privacy-policy')}
                            </a>
                        </BottomLink>
                        <BottomLink>
                            <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href={Routes.COOKIE_POLICY}
                            >
                                {t('bottom-cookie-policy')}
                            </a>
                        </BottomLink>
                    </Links>
                </Content>
            </Container>
        </Wrapper>
    )
}

export default Bottom
