import styled from 'styled-components'

import { colors, media } from '@variables'

export const Wrapper = styled.div<{ readonly isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: ${colors.primary.green1};
    display: ${(props) => (props.isOpen ? 'block' : 'none')};

    a {
        color: inherit;
        text-decoration: none;
    }
`

export const Nav = styled.nav`
    width: 100%;
    display: flex;
    height: 100%;
    padding-top: 120px;
    flex-direction: column;
    color: ${colors.primary.white};

    ${media.custom(340)} {
        padding-top: 160px;
    }
    ${media.custom(420)} {
        padding-top: 200px;
    }
`

export const Ul = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

export const Li = styled.li`
    font-size: 26px;
    font-weight: 500;
    line-height: normal;

    :not(:last-of-type) {
        margin-bottom: 20px;
    }
`

export const Hr = styled.div`
    width: 100%;
    height: 1px;
    opacity: 0.2;
    display: block;
    margin: 30px 0 20px 0;
    background-color: ${colors.primary.white};
`

export const SocialWrap = styled.div`
    display: flex;

    svg,
    path,
    rect {
        color: white;
        fill: white;
    }

    .instagram-path {
        stroke: ${colors.primary.green1};
    }
`
