import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
            {...props}
        >
            <rect
                width="18.75"
                height="18.75"
                x="1.625"
                y="1.625"
                fill="#000"
                rx="6"
            ></rect>
            <path
                stroke="#EAF2FF"
                strokeLinecap="round"
                className="instagram-path"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M11 15.167a4.167 4.167 0 100-8.334 4.167 4.167 0 000 8.334z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M17.125 5.292a.417.417 0 01-.833 0"
            ></path>
            <path
                stroke="#EAF2FF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M16.292 5.292a.417.417 0 11.833 0"
            ></path>
        </svg>
    )
}

export default Icon
