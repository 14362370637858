import React from 'react'
import styled from 'styled-components'

import Img from '@static/images/appstore.webp'

type Props = {
    readonly style?: React.CSSProperties
    readonly size: 'small' | 'medium' | 'large'
}

const AppleStoreButton = ({ size, style = {} }: Props) => {
    return (
        <Wrapper
            size={size}
            style={style}
            target="_blank"
            rel="noreferrer noopener"
            href="https://apps.apple.com/us/app/vos-self-reflect-journal/id1535250944"
        >
            <img
                src={Img}
                width="139"
                height="46"
                loading="lazy"
                alt="download from google play"
            />
        </Wrapper>
    )
}

const Wrapper = styled.a<Props>`
    overflow: hidden;
    position: relative;
    border-radius: 7px;

    width: auto;
    height: ${({ size }) => {
        if (size === 'small') {
            return '34px'
        }
        if (size === 'medium') {
            return '40px'
        }
        return '46px'
    }};

    img {
        width: auto;
        height: ${({ size }) => {
            if (size === 'small') {
                return '34px'
            }
            if (size === 'medium') {
                return '40px'
            }
            return '46px'
        }};
    }
`

export default AppleStoreButton
