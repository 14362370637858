import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { useSetState, useMedia, useEffectOnce } from 'react-use'
import React, { useEffect, useContext, CSSProperties } from 'react'

import { colors, Routes } from '@variables'
import MobileMenu from './MobileMenu'
import { Web3Context } from 'client/context/Web3'
import { scrollTo, copyToClipboard } from '@utils'
import LogoImg from '@static/images/top-logo.webp'
import Dot from 'client/components/shared/AnimatedDot'
import Social from 'client/components/shared/Social'
import Checkmark from 'client/components/SVG/GreenCheckmark'
import LogoWhiteImg from '@static/images/top-logo-white.webp'
import useDocumentScrollThrottled from './useDocumentScrollThrottled'
import {
    Nav,
    Logo,
    Burger,
    Right,
    Menu,
    MenuItem,
    Inner,
    SocialWrap,
    WalletStatus,
    Container,
    WalletInner,
    Wallet,
    Address,
    Disconnect
} from './styled'

const NavBar = () => {
    const [t] = useTranslation()

    const [{ visible, filled, isMobileMenuOpen }, setState] = useSetState({
        visible: true,
        filled: false,
        prevScrollpos: 0,
        isMobileMenuOpen: false
    })

    const { hahdleMintButton, onDisconnect, selectedAccount } = useContext(
        Web3Context
    )

    const isWide = useMedia('(min-width: 960px)')
    const isWiderThanMobile = useMedia('(min-width: 500px)')

    const toggleOpen = () => {
        setState({ isMobileMenuOpen: !isMobileMenuOpen })
        if (isMobileMenuOpen) document.body.classList.remove('block-scroll')
        else document.body.classList.add('block-scroll')
    }

    const closeMobileMenu = () => {
        setState({ isMobileMenuOpen: false })
        document.body.classList.remove('block-scroll')
    }

    useDocumentScrollThrottled(
        ({
            previousScrollTop,
            currentScrollTop
        }: {
            previousScrollTop: number
            currentScrollTop: number
        }) => {
            const isScrolledUp = previousScrollTop > currentScrollTop

            setTimeout(() => {
                setState({
                    visible: currentScrollTop < 25 ? true : isScrolledUp,
                    filled: currentScrollTop > 25 ? true : false
                })
            }, 0)
        }
    )

    useEffect(() => {
        if (isWide) {
            setState({ isMobileMenuOpen: false })
            document.body.classList.remove('block-scroll')
        }
    }, [isWide])

    useEffectOnce(() => {
        loadAndInitGAPI()
    })

    return (
        <>
            <Nav
                isVisible={visible}
                isBkgFilled={filled}
                isMobileMenuOpen={isMobileMenuOpen}
                selectedAccount={Boolean(selectedAccount)}
            >
                <Wallet>
                    <WalletInner>
                        <WalletStatus>
                            <Checkmark />
                            <span>Wallet connected</span>
                        </WalletStatus>
                        <Address
                            onClick={() => {
                                copyToClipboard(selectedAccount)
                                toast(t('copied'), {
                                    icon: t('emoji-copy'),
                                    position: 'bottom-left'
                                })
                            }}
                        >
                            <span>
                                {!isWiderThanMobile
                                    ? `${selectedAccount.substring(
                                          0,
                                          6
                                      )} ... ${selectedAccount.substring(
                                          selectedAccount.length - 6,
                                          selectedAccount.length
                                      )}`
                                    : selectedAccount}
                            </span>
                        </Address>
                        <Disconnect onClick={onDisconnect}>
                            {t('disconnect')}
                        </Disconnect>
                    </WalletInner>
                </Wallet>
                <Container>
                    <Inner>
                        <Logo onClick={closeMobileMenu}>
                            <Link to={Routes.HOME}>
                                <img
                                    src={
                                        isMobileMenuOpen
                                            ? LogoWhiteImg
                                            : LogoImg
                                    }
                                    alt="VOS - Logo"
                                    loading="lazy"
                                    width="159"
                                    height="50"
                                />
                            </Link>
                        </Logo>

                        <Menu>
                            <MenuItem>
                                <Link
                                    to={`${Routes.HOME}`}
                                    onClick={() =>
                                        scrollTo(Routes.COLLECTION_HASH)
                                    }
                                >
                                    {t('menu-collection')}
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Link
                                    to={`${Routes.HOME}`}
                                    onClick={() => scrollTo(Routes.APP_HASH)}
                                >
                                    {t('menu-app')}
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Link
                                    to={`${Routes.HOME}`}
                                    onClick={() =>
                                        scrollTo(Routes.ROADMAP_HASH)
                                    }
                                >
                                    {t('menu-roadmap')}
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Link
                                    to={`${Routes.HOME}`}
                                    onClick={() =>
                                        scrollTo(Routes.BENEFITS_HASH)
                                    }
                                >
                                    {t('menu-benefits')}
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Link
                                    to={`${Routes.HOME}`}
                                    onClick={() => scrollTo(Routes.ABOUT_HASH)}
                                >
                                    {t('menu-about')}
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Link
                                    to={`${Routes.HOME}`}
                                    onClick={() => scrollTo(Routes.TEAM_HASH)}
                                >
                                    {t('menu-team')}
                                </Link>
                            </MenuItem>

                            {Boolean(selectedAccount) && (
                                <MenuItem style={dotLiStyle}>
                                    <Link
                                        to={`${Routes.COLLECTION}`}
                                        style={{ color: colors.primary.green1 }}
                                    >
                                        {t('my-collection')}
                                    </Link>
                                    <Dot style={dotStyle} />
                                </MenuItem>
                            )}
                        </Menu>

                        <Right>
                            <SocialWrap>
                                <Social type="instagram" />
                                <Social type="discord" />
                                <Social type="twitter" />
                                <Social type="etherscan" />
                                <Social type="opensea" />
                            </SocialWrap>

                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={async () => {
                                    closeMobileMenu()
                                    await hahdleMintButton()
                                }}
                            >
                                {t('mint-now')}
                            </Button>
                        </Right>

                        <Burger
                            onClick={toggleOpen}
                            aria-label="burger-menu"
                            isOpen={isMobileMenuOpen}
                        >
                            <span />
                            <span />
                            <span />
                        </Burger>
                    </Inner>
                </Container>
            </Nav>

            <MobileMenu
                isOpen={isMobileMenuOpen}
                closeMobileMenu={closeMobileMenu}
            />
        </>
    )
}

const dotLiStyle = {
    display: 'flex',
    paddingRight: 0
}

const dotStyle = {
    top: -4,
    marginLeft: 4,
    position: 'relative'
} as CSSProperties

const loadAndInitGAPI = () => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://apis.google.com/js/api.js'
        script.async = true
        script.defer = true
        document.getElementsByTagName('head')[0].appendChild(script)
        resolve(null)
    })
}

export default NavBar
