/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import XHR from 'i18next-xhr-backend'
import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector, {
    DetectorOptions
} from 'i18next-browser-languagedetector'

const detectorOptions: DetectorOptions = {
    // cache user language on
    caches: ['localStorage', 'cookie'],

    // optional expire and domain for set cookie
    cookieMinutes: 30 * 24 * 60
}

const options: InitOptions = {
    fallbackLng: 'en',
    // 'tr', 'de', 'pt', 'cs'
    whitelist: ['en'],
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: false,
    saveMissing: false,

    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
        addPath: '/locales/{{lng}}/{{ns}}.json'
    },

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
        format: (value, format, lng) => {
            if (format === 'uppercase') {
                return value.toUpperCase()
            }
            return value
        }
    },
    detection: detectorOptions
}

// for browser use xhr backend to load translations and browser lng detector
if (process && !process.release) {
    i18n.use(XHR).use(initReactI18next).use(LanguageDetector)
}

// initialize if not already initialized
if (!i18n.isInitialized) {
    i18n.init(options)
}

export default i18n
