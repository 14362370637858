import styled, { css } from 'styled-components'
import MuiContainer from '@material-ui/core/Container'

import { AccessibleButton } from '../shared/styled'
import { transition, colors, media } from '@variables'

export const Container = styled(MuiContainer)``

export const Wallet = styled.div`
    width: 100%;
    height: 38px;
    z-index: 1003;
    position: relative;
    color: ${colors.primary.black};
    background-color: ${colors.green[50]};

    padding: 0 16px;

    ${media.custom(600)} {
        padding: 0 24px;
    }
`

export const WalletInner = styled.div`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 1290px;
    display: flex;
    justify-content: flex-end;
`

export const Address = styled.p`
    cursor: copy;
    font-size: 12px;
    line-height: 38px;
    margin-right: 12px;
    transition: ${transition.main};

    ${media.custom(340)} {
        margin-right: 18px;
    }
    ${media.tablet} {
        :hover {
            text-decoration: underline;
        }
    }
`

export const WalletStatus = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.primary.green1};

    font-size: 10px;
    margin-right: 12px;

    ${media.custom(340)} {
        font-size: 11px;
        margin-right: 18px;
    }

    svg {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        max-height: 12px;
        margin-right: 6px;
    }
`

export const Disconnect = styled(AccessibleButton)`
    font-size: 12px;
    cursor: pointer;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: ${colors.primary.green1};
`

export const Nav = styled.nav<{
    readonly isVisible: boolean
    readonly isBkgFilled: boolean
    readonly selectedAccount: boolean
    readonly isMobileMenuOpen: boolean
}>`
    width: 100%;
    z-index: 1000;
    position: fixed;
    overflow: visible;
    will-change: transform;
    transition: transform ease-out 0.2s;

    ${({ selectedAccount, isVisible }) =>
        selectedAccount
            ? css`
                  height: 118px;
                  transform: ${isVisible
                      ? 'translateY(0px)'
                      : 'translateY(-118px)'};

                  ${media.xl} {
                      height: 148px;
                      transform: ${isVisible
                          ? 'translateY(0px)'
                          : 'translateY(-148px)'};
                  }

                  ${Inner} {
                      height: calc(100% - 38px);
                  }
              `
            : css`
                  height: 80px;
                  transform: ${isVisible
                      ? 'translateY(0px)'
                      : 'translateY(-80px)'};

                  ${media.xl} {
                      height: 110px;
                      transform: ${isVisible
                          ? 'translateY(0px)'
                          : 'translateY(-110px)'};
                  }

                  ${Wallet} {
                      display: none;
                  }
              `}

    ::before {
        transition: opacity ease-in 0.1s;
        background-color: white;
        will-change: opacity;
        position: absolute;
        display: block;
        z-index: 1001;
        content: '';
        height: 100%;
        width: 100%;
        opacity: 0;
        top: 0;

        ${media.xl} {
            z-index: 1002;
        }
    }

    ::after {
        background-color: ${colors.primary.green1};
        transition: opacity ease-in 0.1s;
        will-change: opacity;
        position: absolute;
        display: block;
        content: '';
        height: 100%;
        width: 100%;
        opacity: 0;
        top: 0;

        z-index: 1002;

        ${media.xl} {
            z-index: 1001;
        }
    }

    ${(props) =>
        props.isMobileMenuOpen && props.isVisible
            ? `:after { opacity: 1; } `
            : `:after { opacity: 0; }`}

    ${(props) =>
        props.isBkgFilled
            ? `:before { opacity: 1; } `
            : `:before { opacity: 0; }`}

    a {
        text-decoration: none;
        color: ${colors.primary.black};
    }

    ${Container} {
        height: 100%;
        z-index: 1003;
        position: relative;
        max-width: 1338px !important;
    }
`

export const Logo = styled.div`
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    z-index: 1;
    position: relative;

    a {
        height: 35px;

        ${media.custom(370)} {
            height: 50px;
        }
    }

    img {
        height: auto;
        max-width: 110px;

        ${media.custom(370)} {
            max-width: 159px;
        }
    }
`

export const Inner = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`

export const Burger = styled(AccessibleButton)<{ readonly isOpen: boolean }>`
    width: 40px;
    height: 40px;
    display: flex;
    cursor: pointer;
    border-radius: 40px;
    align-items: center;
    will-change: opacity;
    flex-direction: column;
    justify-content: center;
    transition: ${transition.main};
    background-color: ${colors.primary.green1};
    overflow: hidden;
    position: relative;

    ::before {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        will-change: opacity;
        transition: ${transition.main};
        background-color: ${colors.primary.green3};
    }

    ${({ isOpen }) => isOpen && ` :before { opacity: 1; } `};

    ${media.xl} {
        display: none;
    }

    span {
        width: 12px;
        height: 1px;
        border-radius: 4px;
        position: relative;
        will-change: transform opacity;
        transition: ${transition.main};
        background-color: ${(props) =>
            props.isOpen ? colors.primary.green1 : colors.primary.white};

        ${(props) =>
            props.isOpen &&
            `
            transform-origin: 47%;

            :nth-of-type(1) {
                transform: rotate(45deg);
            }
            :nth-of-type(2) {
                transform: rotate(-45deg);
            }
            :last-of-type {
                opacity: 0;
            }
        `}

        ${(props) =>
            !props.isOpen && `:not(:last-of-type) { margin-bottom: 3px; }`}
    }
`

export const Right = styled.div`
    z-index: 1;
    position: relative;
    margin: 0 10px 0 auto;

    @media (max-width: 1169px) {
        button {
            padding: 13px 21px;
        }
    }

    ${media.xl} {
        margin: 0;
        display: flex;

        button {
            margin-left: 20px;
        }
    }
`

export const SocialWrap = styled.div`
    display: none;

    div {
        width: 33px;
        height: 33px;

        svg {
            width: 18px;
            height: auto;
        }
    }

    ${media.xl} {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
`

export const Menu = styled.ul`
    display: none;

    ${media.xl} {
        right: 0;
        padding: 0;
        left: -160px;
        margin: auto;
        display: flex;
        text-indent: 0;
        z-index: 0;
        position: absolute;
        list-style-type: none;
        justify-content: center;
    }
`

export const MenuItem = styled.li`
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    padding-right: 20px;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    ${media.xl} {
        :hover {
            a {
                text-decoration: underline;
            }
        }
    }
`
