import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
            {...props}
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M16.108 7.463h-3.55V5.87a.834.834 0 01.834-.917h2.5V1.621h-3.609c-3.275 0-3.891 2.5-3.891 4.05v1.792h-2.5v3.333h2.5v9.583h4.166v-9.583h3.209l.341-3.333z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default Icon
