import React from 'react'
import styled from 'styled-components'

import { colors, media, Routes, transition } from '@variables'
import Discord from 'client/components/SVG/Social/discord'
import Facebook from 'client/components/SVG/Social/facebook'
import Instagram from 'client/components/SVG/Social/instagram'
import Linkedin from 'client/components/SVG/Social/linkedin'
import Twitter from 'client/components/SVG/Social/twitter'
import Etherscan from 'client/components/SVG/Social/etherscan'
import Opensea from 'client/components/SVG/Social/opensea'

type Props = {
    type:
        | 'discord'
        | 'facebook'
        | 'instagram'
        | 'linkedin'
        | 'twitter'
        | 'etherscan'
        | 'opensea'
    variant?: 'filled' | 'default'
}

const Wrap = styled.div<Pick<Props, 'variant'>>`
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: ${({ variant }) =>
        variant === 'filled' ? colors.primary.green3 : 'transparent'};

    svg {
        transition: ${transition.main};
    }

    a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        ${media.tablet} {
            :hover {
                svg {
                    transform: scale(1.1);
                }
            }
        }
    }
`

const Social = ({ type, variant = 'default' }: Props) => {
    return (
        <Wrap variant={variant}>
            <a target="_blank" rel="noreferrer noopener" href={getLink(type)}>
                {getIcon(type)}
            </a>
        </Wrap>
    )
}

const getLink = (type: Props['type']) => {
    if (type === 'discord') {
        return Routes.DISCORD
    }
    if (type === 'facebook') {
        return Routes.FACEBOOK
    }
    if (type === 'instagram') {
        return Routes.INSTAGRAM
    }
    if (type === 'linkedin') {
        return Routes.LINKEDIN
    }
    if (type === 'etherscan') {
        return Routes.ETHERSCAN
    }
    if (type === 'opensea') {
        return Routes.OPENSEA
    }
    return Routes.TWITTER
}

const getIcon = (type: Props['type']) => {
    if (type === 'discord') {
        return <Discord className="cta-join-discord" />
    }
    if (type === 'facebook') {
        return <Facebook />
    }
    if (type === 'instagram') {
        return <Instagram />
    }
    if (type === 'linkedin') {
        return <Linkedin />
    }
    if (type === 'etherscan') {
        return <Etherscan />
    }
    if (type === 'opensea') {
        return <Opensea />
    }
    return <Twitter />
}

export default Social
