import { Link } from 'react-router-dom'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'

import { scrollTo } from '@utils'
import { Routes } from '@variables'
import { Web3Context } from 'client/context/Web3'
import Social from 'client/components/shared/Social'
import { Wrapper, Nav, Ul, Li, Hr, SocialWrap } from './styled'

type Props = {
    isOpen: boolean
    closeMobileMenu: () => void
}

const MobileMenu = ({ isOpen, closeMobileMenu }: Props) => {
    const [t] = useTranslation()
    const { selectedAccount } = useContext(Web3Context)

    return (
        <Wrapper isOpen={isOpen}>
            <Container>
                <Nav>
                    <Ul>
                        <Li>
                            <Link
                                to={`${Routes.HOME}`}
                                onClick={() => {
                                    closeMobileMenu()
                                    scrollTo(Routes.COLLECTION_HASH)
                                }}
                            >
                                {t('menu-collection')}
                            </Link>
                        </Li>
                        <Li>
                            <Link
                                to={`${Routes.HOME}`}
                                onClick={() => {
                                    closeMobileMenu()
                                    scrollTo(Routes.APP_HASH)
                                }}
                            >
                                {t('menu-app')}
                            </Link>
                        </Li>
                        <Li>
                            <Link
                                to={`${Routes.HOME}`}
                                onClick={() => {
                                    closeMobileMenu()
                                    scrollTo(Routes.ROADMAP_HASH)
                                }}
                            >
                                {t('menu-roadmap')}
                            </Link>
                        </Li>
                        <Li>
                            <Link
                                to={`${Routes.HOME}`}
                                onClick={() => {
                                    closeMobileMenu()
                                    scrollTo(Routes.BENEFITS_HASH)
                                }}
                            >
                                {t('menu-benefits')}
                            </Link>
                        </Li>
                        <Li>
                            <Link
                                to={`${Routes.HOME}`}
                                onClick={() => {
                                    closeMobileMenu()
                                    scrollTo(Routes.ABOUT_HASH)
                                }}
                            >
                                {t('menu-about')}
                            </Link>
                        </Li>
                        <Li>
                            <Link
                                to={`${Routes.HOME}`}
                                onClick={() => {
                                    closeMobileMenu()
                                    scrollTo(Routes.TEAM_HASH)
                                }}
                            >
                                {t('menu-team')}
                            </Link>
                        </Li>
                        {Boolean(selectedAccount) && (
                            <Li>
                                <Link
                                    to={`${Routes.COLLECTION}`}
                                    onClick={() => {
                                        closeMobileMenu()
                                    }}
                                >
                                    {t('my-collection')}
                                </Link>
                            </Li>
                        )}
                    </Ul>

                    <Hr />

                    <SocialWrap>
                        <Social type="instagram" />
                        <Social type="discord" />
                        <Social type="twitter" />
                        <Social type="etherscan" />
                        <Social type="opensea" />
                    </SocialWrap>
                </Nav>
            </Container>
        </Wrapper>
    )
}

export default MobileMenu
