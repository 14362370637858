export const getScrollTop = () => {
    if (typeof pageYOffset != 'undefined') {
        //most browsers except IE before #9
        return pageYOffset
    } else {
        const B = document.body //IE 'quirks'
        let D = document.documentElement //IE with doctype
        D = D.clientHeight ? D : B
        return D.scrollTop
    }
}

export const smoothScrollToEl = (id: string) => {
    const el = document.getElementById(id)
    if (!el) return
    const offset = el.offsetTop - 90
    window.scroll({
        top: offset,
        left: 0,
        behavior: 'smooth'
    })
}

export const scrollTo = (id: string) => {
    setTimeout(() => {
        smoothScrollToEl(id)
    }, 50)
}

export const copyToClipboard = (input: string) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(input).then(
            () => {
                console.log('Copied to clipboard successfully.')
            },
            (err) => {
                console.log('Failed to copy the text to clipboard.', err)
            }
        )
    }
}
