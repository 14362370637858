import styled from 'styled-components'

import { colors, media } from '@variables'

export const Wrap = styled.div`
    flex: 0 0 auto;
`

export const Wrapper = styled.footer`
    width: 100%;
    position: relative;
    padding: 55px 0;
    background-color: white;

    ${media.desktop} {
        padding: 120px 0;
    }
`

export const Ul = styled.ul`
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    order: 3;

    :last-of-type {
        padding-top: 28px;
    }

    ${media.desktop} {
        order: inherit;

        :last-of-type {
            padding-top: 0;
        }
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    ${media.desktop} {
        flex-direction: row;
        align-items: flex-start;
    }

    @media (max-width: 959px) {
        text-align: center;
    }

    a {
        color: inherit;
        text-decoration: none;

        :not(:last-of-type) {
            padding-bottom: 12px;
        }

        ${media.tablet} {
            :hover {
                text-decoration: underline;
            }
        }
    }

    a,
    p,
    span {
        font-size: 14px;
        line-height: normal;
    }

    h6 {
        padding-bottom: 24px;
    }
`

export const LogoWrap = styled.div`
    margin-bottom: 35px;
    order: 1;

    img {
        width: 170px;
        height: auto;
    }

    ${media.desktop} {
        order: inherit;
        margin-bottom: 0;
    }
`

export const Col = styled.div`
    display: flex;
    flex-direction: column;
`

export const SocialWrap = styled(Col)`
    order: 4;
    margin-top: 50px;

    ${media.desktop} {
        order: inherit;
        margin-top: 0;
    }
`

export const AppsWrap = styled(Col)`
    order: 2;
    margin-bottom: 45px;

    ${media.desktop} {
        order: inherit;
        margin-bottom: 0px;
    }
`

export const Apps = styled.div`
    display: flex;
    flex-direction: row;

    ${media.desktop} {
        flex-direction: column;
    }

    a {
        :last-of-type {
            margin: 0 0 0 15px;

            ${media.desktop} {
                margin: 15px 0 0 0;
            }
        }
    }
`

export const Logo = styled.img`
    height: auto;
    max-width: 94px;
`

export const SocialInner = styled.div`
    display: flex;
    padding-bottom: 24px;

    div {
        :not(:last-of-type) {
            margin-right: 16px;
        }
    }
`

export const Langs = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    padding-top: 30px;
    justify-content: center;

    ${media.desktop} {
        justify-content: flex-start;
    }
`

export const Contacts = styled.div`
    display: flex;
    flex-direction: column;

    a {
        :not(:last-of-type) {
            color: ${colors.primary.green1};
        }
    }
`
