import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Web3Context } from 'client/context/Web3'
import React, { useContext, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import { colors } from '@variables'

export const Wrap = styled.div<{ isLoading: boolean }>`
    width: 100vw;
    height: 100vh;
    z-index: 4000;
    position: fixed;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    visibility: visible;
    padding-bottom: 35px;
    background-color: white;

    ${({ isLoading }) =>
        !isLoading &&
        css`
            position: -999999;
            visibility: hidden;
        `}
`

const Loader = () => {
    const [t] = useTranslation()
    const { loading } = useContext(Web3Context)

    useEffect(() => {
        if (!document) return

        const isBlocked = document.body.classList.contains('block-scroll')

        if (!isBlocked && loading) {
            document.body.classList.add('block-scroll')
        }

        if (isBlocked && !loading) {
            document.body.classList.remove('block-scroll')
        }

        return () => {
            document.body.classList.remove('block-scroll')
        }
    }, [loading])

    return (
        <Wrap isLoading={loading}>
            <Typography variant="h2">{t('loading-title')}</Typography>
        </Wrap>
    )
}

export default Loader
