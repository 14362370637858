import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
            {...props}
        >
            <path
                fill="#000"
                d="M11 1C5.478 1 1 5.478 1 11s4.478 10 10 10 10-4.478 10-10S16.524 1 11 1zM5.934 11.336l.042-.068 2.602-4.07c.038-.058.128-.052.156.012.434.974.81 2.186.634 2.94-.074.31-.28.73-.512 1.118-.03.056-.062.112-.098.166a.088.088 0 01-.074.038H6.01a.088.088 0 01-.076-.136zm11.594 1.4a.09.09 0 01-.054.084c-.202.086-.892.404-1.178.802-.732 1.018-1.29 2.474-2.54 2.474H8.544A3.349 3.349 0 015.2 12.74v-.06a.09.09 0 01.09-.088h2.904c.058 0 .1.052.096.11a.977.977 0 00.104.558c.172.35.53.568.916.568h1.438v-1.122H9.326a.092.092 0 01-.074-.144l.052-.076c.134-.192.326-.488.518-.826a6.64 6.64 0 00.358-.716c.02-.044.036-.09.054-.134.028-.078.056-.152.076-.224.02-.062.038-.126.054-.186a2.92 2.92 0 00.068-.656c0-.09-.004-.184-.012-.272a3.652 3.652 0 00-.028-.294c-.008-.086-.024-.172-.04-.26-.022-.13-.05-.26-.082-.39l-.012-.05c-.024-.09-.046-.174-.074-.264a9.853 9.853 0 00-.274-.81 4.343 4.343 0 00-.118-.296c-.06-.148-.122-.282-.178-.408a2.97 2.97 0 01-.078-.164c-.028-.06-.056-.12-.086-.178-.02-.044-.044-.086-.06-.126l-.176-.324c-.024-.044.016-.098.064-.084l1.1.298h.008l.144.042.16.044.058.016v-.652c0-.316.252-.572.566-.572a.55.55 0 01.398.168.575.575 0 01.166.404v.97l.118.032c.008.004.018.008.026.014l.122.092c.042.032.086.072.138.114.106.086.234.196.372.322a8.67 8.67 0 01.676.674c.054.062.106.122.16.188.052.066.11.13.158.194.066.086.134.176.196.27.028.044.062.09.088.134.08.118.148.24.214.362.028.056.056.118.08.178.074.164.132.33.168.498.012.036.02.074.024.11v.008c.012.048.016.1.02.154a1.714 1.714 0 01-.088.728c-.026.07-.05.142-.082.212a2.877 2.877 0 01-.22.418c-.028.05-.062.102-.094.152-.036.052-.074.102-.106.15a3.242 3.242 0 01-.144.184 4.69 4.69 0 01-.342.406c-.04.048-.084.098-.13.142-.044.05-.09.094-.13.134-.07.07-.126.122-.174.168l-.114.102a.085.085 0 01-.06.024h-.876v1.122h1.102c.246 0 .48-.086.67-.248.064-.056.346-.3.68-.668a.079.079 0 01.042-.026l3.042-.88a.089.089 0 01.114.086v.644z"
            ></path>
        </svg>
    )
}

export default Icon
