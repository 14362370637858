import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="none"
            viewBox="0 0 22 22"
            {...props}
        >
            <path
                fill="#131514"
                d="M20.634 3.031c-.814.385-2.201 1.107-2.899 1.245l-.075.023A4.5 4.5 0 0010 7.5c0 .131-.01.372 0 .5-3.217 0-5.567-1.679-7.326-3.837-.235-.29-.485-.139-.537.067-.117.466-.157 1.245-.157 1.801 0 1.401 1.095 2.777 2.8 3.63-.314.081-.66.14-1.02.14a2.97 2.97 0 01-1.34-.336c-.157-.083-.498-.06-.397.344.405 1.62 2.253 2.756 3.904 3.087-.375.221-1.175.176-1.543.176-.136 0-.61-.032-.915-.07-.28-.034-.708.038-.35.582.772 1.167 2.516 1.9 4.017 1.928-1.382 1.084-3.642 1.48-5.807 1.48-.438-.01-.416.49-.063.674C2.863 18.504 5.479 19 7.348 19 14.778 19 19 13.337 19 8l-.005-.448c0-.018.005-.035.005-.053 0-.027-.008-.053-.008-.08a18.384 18.384 0 00-.009-.329c.59-.425 1.491-1.163 1.947-1.728.155-.192.03-.425-.18-.352-.544.19-1.483.555-2.07.625 1.177-.779 1.759-1.457 2.259-2.21.17-.257-.044-.518-.305-.394z"
            ></path>
        </svg>
    )
}

export default Icon
