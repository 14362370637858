import moment from 'moment-timezone'
import { createMuiTheme } from '@material-ui/core/styles'

export const now = moment.tz(moment.now(), 'Europe/Prague').toDate()

export const dateOfStartPrivate = moment
    .tz('2022-03-31 11:40', 'Europe/Prague')
    .toDate()

export const dateOfStartPublic = moment
    .tz('2022-03-31 11:50', 'Europe/Prague')
    .toDate()

export const isDevWeb =
    process.env.RAZZLE_HOST?.includes('test') ||
    process.env.RAZZLE_HOST?.includes('localhost')

export const contractUrl = `https://${
    isDevWeb ? 'rinkeby.' : ''
}etherscan.io/address/${process.env.RAZZLE_CONTRACT_ADDRESS}`

enum BasicRoutes {
    HOME = '/',
    MINT = '/mint',
    COLLECTION = '/collection'
}

enum ExternalRoutes {
    ABOUT_TEAM = 'https://vos.health/about',
    CAREER = 'https://vos.health/career',
    FOR_INDIVIDUALS = 'https://vos.health',
    FOR_INDIVIDUALS_BLOG = 'https://vos.health/stories',
    FOR_INDIVIDUALS_ABOUT = 'https://vos.health/about',
    DISCORD = 'https://discord.gg/xy2HQgRpcd',
    TWITTER = 'https://twitter.com/voshealth_',
    FOR_BUSINESS = 'https://business.vos.health',
    FACEBOOK = 'https://www.facebook.com/vos.health',
    INSTAGRAM = 'https://www.instagram.com/vos.health',
    COOKIE_POLICY = 'https://vos.health/cookie-policy',
    PRIVACY_POLICY = 'https://vos.health/privacy-policy',
    LINKEDIN = 'https://www.linkedin.com/company/vos-health-selfreflection',
    FORBES = 'https://forbes.cz/dalsich-dvacet-milionu-do-dusevniho-zdravi-ceskou-aplikaci-vos-health-podporil-reflex-capital-miliardare-fryce/',
    CZECHCRUNCH = 'https://cc.cz/mene-stresu-a-spokojenejsi-zivot-miton-investuje-5-milionu-korun-do-ceskeho-digitalniho-deniku-vos/',
    DAILY = 'https://dailyplanetdc.com/2021/06/30/vos-health-a-mental-health-care-platform-has-received-another-investment/',
    FOR_BUSINESS_BLOG = 'https://business.vos.health/stories',
    FOR_BUSINESS_ABOUT = 'https://business.vos.health/about',
    OPENSEA = 'https://opensea.io/collection/vosnft',
    ETHERSCAN = 'https://etherscan.io/address/0x0c86d36e02544f9743d1765bcd60ae14a44eae1e',
    FOR_HOLDERS = 'https://poised-playroom-da3.notion.site/For-VOS-NFT-Holders-d48667e43ea148b5aef88cd3f257c0c0'
}

enum HashParams {
    APP_HASH = 'app',
    TEAM_HASH = 'team',
    ABOUT_HASH = 'about',
    BENEFITS_HASH = 'benefits',
    ROADMAP_HASH = 'roadmap',
    COLLECTION_HASH = 'collection'
}

export type RoutesType = BasicRoutes | HashParams | ExternalRoutes

export const Routes = {
    ...BasicRoutes,
    ...HashParams,
    ...ExternalRoutes
}

export const transition = {
    main: `all linear 0.25s`
}

const customMediaQuery = (minWidth: number): string =>
    `@media (min-width: ${minWidth}px)`

export const media = {
    custom: customMediaQuery,
    xl: customMediaQuery(1170),
    desktop: customMediaQuery(960),
    tablet: customMediaQuery(854),
    phone: customMediaQuery(480)
}

export const colors = {
    primary: {
        black: '#131514',
        white: '#F9FCFB',
        green1: '#225B41',
        green2: '#85B6A1',
        green3: '#E2EFE9'
    },
    green: {
        '50': '#E6F5EE',
        '100': '#CEEDDF',
        '200': '#99DBBD',
        '300': '#5BA885',
        '400': '#337A5A',
        '500': '#1C5C3E'
    },
    red: {
        '50': '#FCF1ED',
        '100': '#FCDED4',
        '200': '#F7B8A8',
        '300': '#F08473',
        '400': '#E56152',
        '500': '#D6392B'
    },
    turquoise: {
        '50': '#DFF7F5',
        '100': '#CDF7F2',
        '200': '#7BDBD0',
        '300': '#4AC2B3',
        '400': '#098F7E',
        '500': '#036155'
    },
    purple: {
        '50': '#F3EDF5',
        '100': '#EEDFF2',
        '200': '#DAAAE5',
        '300': '#B66FC7',
        '400': '#934EA3',
        '500': '#632D70'
    },
    blue: {
        '50': '#EBF5FF',
        '100': '#D6EBFF',
        '200': '#A3D3FF',
        '300': '#5BA8F0',
        '400': '#0979E0',
        '500': '#0060B8'
    },
    yellow: {
        '50': '#FFFAE5',
        '100': '#FFF1C2',
        '200': '#FFDD80',
        '300': '#FFD166',
        '400': '#F5B028',
        '500': '#DB8F00'
    },
    pink: {
        '50': '#FCEDF5',
        '100': '#FCD4E8',
        '200': '#F7A8CF',
        '300': '#F073B1',
        '400': '#E5529B',
        '500': '#C02573'
    },
    grey: {
        '50': '#F6F6F6',
        '100': '#E8E8E8',
        '200': '#D1D1D1',
        '300': '#A2A2A2',
        '400': '#747474',
        '500': '#464646'
    },
    surface: {
        '00': '#ffffff',
        '50': '#E8E8E8',
        '100': '#D1D1D1',
        '200': '#A2A2A2',
        '300': '#8B8B8B',
        '400': '#747474',
        '500': '#5D5D5D',
        '600': '#464646',
        '700': '#2E2E2E',
        '800': '#171717',
        '900': '#000000'
    }
}

export const theme = createMuiTheme({
    typography: {
        fontFamily: ['"GT Eesti Pro Display"', 'sans-serif'].join(',')
    },
    palette: {
        primary: {
            main: colors.primary.black,
            dark: colors.primary.green1,
            contrastText: '#fff'
        },
        secondary: {
            main: colors.primary.green3,
            dark: colors.primary.green1,
            contrastText: colors.primary.black
        }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontWeight: 500,
                    fontSize: '44px',
                    lineHeight: '48px',

                    '@media (min-width: 960px)': {
                        fontSize: '102px',
                        lineHeight: '104px'
                    }
                },
                h2: {
                    fontWeight: 500,
                    fontSize: '34px',
                    lineHeight: '40px',

                    '@media (min-width: 960px)': {
                        fontSize: '72px',
                        lineHeight: '80px'
                    }
                },
                h3: {
                    fontWeight: 500,
                    fontSize: '28px',
                    lineHeight: '32px',

                    '@media (min-width: 960px)': {
                        fontSize: '50px',
                        lineHeight: '64px'
                    }
                },
                h4: {
                    fontWeight: 500,
                    fontSize: '24px',
                    lineHeight: '28px',

                    '@media (min-width: 960px)': {
                        fontSize: '36px',
                        lineHeight: '40px'
                    }
                },
                h5: {
                    fontWeight: 400,
                    fontSize: '21px',
                    lineHeight: '32px',

                    '@media (min-width: 960px)': {
                        fontSize: '26px',
                        lineHeight: '40px'
                    }
                },
                h6: {
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '16px',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase'
                },
                body1: {
                    lineHeight: '28px',
                    fontSize: '16px',
                    fontWeight: 400,

                    '@media (min-width: 960px)': {
                        fontSize: '18px',
                        lineHeight: '28px'
                    }
                },
                body2: {
                    textTransform: 'uppercase',
                    letterSpacing: '0.06em',
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontWeight: 400
                },
                subtitle1: {
                    lineHeight: '20px',
                    fontSize: '14px',
                    fontWeight: 400
                },
                subtitle2: {
                    lineHeight: '16px',
                    fontSize: '12px',
                    fontWeight: 400
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: `#BCD6CE`
                },
                root: {
                    height: 62,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.06)',

                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${colors.primary.green2}`
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 1,
                        borderColor: `${colors.primary.green1}`
                    }
                }
            }
        },
        MuiFormControlLabel: {
            defaultProps: {
                label: {
                    color: colors.primary.black,
                    lineHeight: '20px',
                    fontWeight: 400,
                    fontSize: 14,
                    opacity: 0.6
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    // 1170
                    maxWidth: '1218px !important'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '50px',
                    textTransform: 'none',
                    boxShadow: 'none',

                    '&:hover': { boxShadow: 'none' }
                },
                outlinedPrimary: {
                    backgroundColor: 'transparent',
                    borderColor: 'rgba(0, 0, 0, 0.2)',

                    '&:hover': {
                        borderColor: 'rgba(0, 0, 0, 0.2)',
                        backgroundColor: colors.primary.green3
                    }
                },
                containedPrimary: {
                    color: '#ffffff !important',
                    backgroundColor: colors.primary.black
                },
                containedSecondary: {
                    color: colors.primary.black,
                    backgroundColor: 'white',

                    '&:hover': {
                        color: 'white',
                        backgroundColor: colors.primary.black
                    }
                },
                containedInherit: {
                    border: 0,
                    color: 'white',
                    backgroundColor: colors.primary.green1,

                    '&:hover': {
                        backgroundColor: colors.primary.black
                    }
                },
                outlinedInherit: {
                    color: colors.primary.green1,
                    border: `1px solid ${colors.primary.green1}`,
                    '&:hover': {
                        backgroundColor: colors.primary.green3
                    }
                },
                sizeLarge: {
                    fontWeight: 400,
                    fontSize: '21px',
                    lineHeight: 'normal',
                    padding: '13px 46px',

                    '@media (min-width: 960px)': {
                        fontSize: '26px',
                        padding: '19px 84px'
                    }
                },
                sizeMedium: {
                    fontWeight: 400,
                    fontSize: '18px',
                    lineHeight: 'normal',
                    padding: '15px 64px',

                    '@media (min-width: 960px)': {
                        fontSize: '22px',
                        padding: '17px 74px'
                    }
                },
                sizeSmall: {
                    fontSize: 12,
                    fontWeight: 500,
                    boxShadow: 'none',
                    lineHeight: 'normal',
                    padding: '18px 46px',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase'
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    overflow: 'visible',
                    color: colors.primary.green1,
                    borderBottom: `4px solid #B4D2C5`,

                    '& > .MuiTabs-scroller': {
                        bottom: -4
                    }
                },
                indicator: {
                    backgroundColor: colors.primary.green1,
                    height: 4
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    textTransform: 'none',
                    opacity: '0.5 !important',

                    '&$selected': {
                        opacity: '1 !important'
                    }
                }
            }
        }
    }
})

import { AbiItem } from 'web3-utils'

export const contractABI: AbiItem[] = [
    { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'approved',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256'
            }
        ],
        name: 'Approval',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'operator',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'bool',
                name: 'approved',
                type: 'bool'
            }
        ],
        name: 'ApprovalForAll',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'contract IERC20',
                name: 'token',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'to',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256'
            }
        ],
        name: 'ERC20PaymentReleased',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'previousOwner',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'newOwner',
                type: 'address'
            }
        ],
        name: 'OwnershipTransferred',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
            }
        ],
        name: 'Paused',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'shares',
                type: 'uint256'
            }
        ],
        name: 'PayeeAdded',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'from',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256'
            }
        ],
        name: 'PaymentReceived',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'to',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256'
            }
        ],
        name: 'PaymentReleased',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
            },
            {
                indexed: true,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256'
            }
        ],
        name: 'Transfer',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
            }
        ],
        name: 'Unpaused',
        type: 'event'
    },
    {
        inputs: [],
        name: 'TOKEN_LIMIT',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' }
        ],
        name: 'approve',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'baseURI',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'disablePublicSale',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'enablePublicSale',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'getApproved',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'getMaxTokensAtOnce',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'getTokenPrice',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'owner', type: 'address' },
            { internalType: 'address', name: 'operator', type: 'address' }
        ],
        name: 'isApprovedForAll',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }],
        name: 'mintPublicMultipleTokens',
        outputs: [],
        stateMutability: 'payable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }],
        name: 'mintTeamMultipleTokens',
        outputs: [],
        stateMutability: 'payable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'ownerOf',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'paused',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
        name: 'payee',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'publicSale',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address payable',
                name: 'account',
                type: 'address'
            }
        ],
        name: 'release',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    // {
    //     inputs: [
    //         { internalType: 'contract IERC20', name: 'token', type: 'address' },
    //         { internalType: 'address', name: 'account', type: 'address' }
    //     ],
    //     name: 'release',
    //     outputs: [],
    //     stateMutability: 'nonpayable',
    //     type: 'function'
    // },
    // {
    //     inputs: [
    //         { internalType: 'contract IERC20', name: 'token', type: 'address' },
    //         { internalType: 'address', name: 'account', type: 'address' }
    //     ],
    //     name: 'released',
    //     outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    //     stateMutability: 'view',
    //     type: 'function'
    // },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'released',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' }
        ],
        name: 'safeTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
            { internalType: 'bytes', name: '_data', type: 'bytes' }
        ],
        name: 'safeTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'operator', type: 'address' },
            { internalType: 'bool', name: 'approved', type: 'bool' }
        ],
        name: 'setApprovalForAll',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'string', name: '_uri', type: 'string' }],
        name: 'setBaseUri',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'uint256', name: '_count', type: 'uint256' }],
        name: 'setMaxTokensAtOnce',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'uint256', name: '_price', type: 'uint256' }],
        name: 'setTokenPrice',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'shares',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
        ],
        name: 'supportsInterface',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'teamSale',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'togglePaused',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [],
        name: 'toggleTeamSale',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
        name: 'tokenByIndex',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'owner', type: 'address' },
            { internalType: 'uint256', name: 'index', type: 'uint256' }
        ],
        name: 'tokenOfOwnerByIndex',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'tokenURI',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'contract IERC20', name: 'token', type: 'address' }
        ],
        name: 'totalReleased',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'totalReleased',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'totalShares',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'tokenId', type: 'uint256' }
        ],
        name: 'transferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            { internalType: 'address', name: 'newOwner', type: 'address' }
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    { stateMutability: 'payable', type: 'receive' }
]
