import styled from 'styled-components'

import { colors, media, transition } from '@variables'

export const GrowInMind = styled.div`
    z-index: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    display: flex;
    text-align: center;
    padding: 20px 0;
    justify-content: center;

    ${media.tablet} {
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0;
        position: absolute;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    padding: 20px 0;
    position: relative;
    color: ${colors.primary.white};
    background-color: ${colors.primary.black};
`

export const Links = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
    }
`

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    ${media.tablet} {
        flex-direction: row;
    }
`

export const Copyright = styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 14px;
    position: relative;

    ${media.tablet} {
        margin-bottom: 0px;
    }
`

export const BottomLink = styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    margin: 8px 0;

    ${media.tablet} {
        margin: 0;
        text-align: left;
    }

    a {
        color: inherit;
        text-decoration: inherit;
        transition: ${transition.main};

        ${media.tablet} {
            :hover {
                text-decoration: underline;
            }
        }
    }

    :first-child {
        ${media.tablet} {
            padding-right: 25px;
        }
    }
`
